export enum EGameFieldStates {
  BOMB,
  DEFAULT,
  CURRENT,
  PASSED,
}

export enum EGameFieldTypes {
  ONE,
  LINE,
}

export interface IGameFieldProps {
  isShuffling?: boolean;
  state: EGameFieldStates;
  type: EGameFieldTypes;
}

export interface IGameFieldEmits {
  (event: 'interact'): void;
}
