<template>
  <div class="instructions-item">
    <div v-if="changeXL(true, false)" class="instructions-item__image">
      <component :is="icon" v-if="icon" />
    </div>
    <div class="instructions-item__text">
      <slot name="text" />
    </div>
  </div>
</template>

<script setup lang="ts">
import type { IInstructionsItemProps } from './InstructionsItem.types';

defineProps<IInstructionsItemProps>();

const changeXL = GlobalUtils.Media.changeByMedia('xl');
</script>

<style scoped lang="scss" src="./InstructionsItem.scss" />
