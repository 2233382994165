<template>
  <section class="instructions-controller">
    <TechiesInstructionsItem icon="SvgoTechiesInstruction1">
      <template #text> Раздефузь бомбу и получи <span class="instructions-item--gold">кейс бесплатно</span>! </template>
    </TechiesInstructionsItem>
    <TechiesInstructionsItem icon="SvgoTechiesInstruction2">
      <template #text>
        <span class="instructions-item--gold">Увеличивай свои шансы</span>, выбирая игру дороже!
      </template>
    </TechiesInstructionsItem>
    <TechiesInstructionsItem icon="SvgoTechiesInstruction3">
      <template #text>Чем дороже игра - тем <span class="instructions-item--gold">сочнее призы</span>!</template>
    </TechiesInstructionsItem>
  </section>
</template>

<script setup lang="ts"></script>

<style scoped lang="scss" src="/features/techies/controllers/InstructionsController/InstructionsController.scss" />
