<template>
  <div :class="['final-prize', rootClasses]">
    <div class="final-prize--background"></div>
    <div class="final-prize__top">
      <span class="final-prize__top-title">C4 Bomb</span>
      <div class="final-prize__top-hp">
        <component :is="hpBar" />
        <div class="final-prize__top-hp--filled"></div>
      </div>
    </div>
    <div class="final-prize__image">
      <NuxtImg :src="ancient" provider="localProvider" loading="lazy" />
    </div>
    <div class="final-prize__bottom">
      <span class="final-prize__bottom-current">Бесплатно</span>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { IFinalPrizeProps } from '~/features/techies/components/FinalPrize/FinalPrize.types';

const props = defineProps<IFinalPrizeProps>();
const { isEnd } = toRefs(props);

const changeXL = GlobalUtils.Media.changeByMedia('xl');

const hpBar = computed(() => changeXL('SvgoTechiesHpBar', 'SvgoTechiesHpBarM'));

const rootClasses = computed(() => ({
  'final-prize--won': isEnd.value,
}));

const ancient = computed(() => getS3Image('techies', changeXL('ancient', 'ancient-m')));
</script>

<style scoped lang="scss" src="./FinalPrize.scss" />
