import type { ISharedKitButtonColorPreset } from '~/components/SharedKitButton/SharedKitButton.types';
import { Colors } from '~/constants/colors.constants';

type TColorPresetsKeys = 'DEFAULT' | 'DISABLED';

export const TechiesButtonPresets: Record<TColorPresetsKeys, ISharedKitButtonColorPreset> = {
  DEFAULT: {
    defaultBackground: Colors.NEUTRAL.WHITE_15,
    // Цвет ховера должен быть 0.3 прозрачности, снизу 0.15 из-за наложения
    hoverBackground: Colors.NEUTRAL.WHITE_15,
    loadingColor: Colors.NEUTRAL.BLACK_30,
    textColor: Colors.NEUTRAL.WHITE,
  },
  DISABLED: {
    defaultBackground: Colors.NEUTRAL.BLACK_30,
    hoverBackground: Colors.NEUTRAL.BLACK_30,
    loadingColor: Colors.NEUTRAL.WHITE_15,
    textColor: Colors.NEUTRAL.WHITE_65,
  },
};
