import type { Type } from '~/repository/modules/auth';

export enum EButtonBlockStates {
  DEFAULT,
  INGAME,
  UNAUTH,
}

export interface IButtonBlockOther {
  state: EButtonBlockStates.INGAME | EButtonBlockStates.UNAUTH | EButtonBlockStates.DEFAULT;
}

export interface IButtonBlockProps {
  loading: boolean;
  shuffleLoading: boolean;
  state: EButtonBlockStates;
}

export interface IButtonBlockEmits {
  (e: 'start', variant: number): void;
  (e: 'hover', column: number): void;
  (e: 'leave'): void;
  (e: 'auth', type: Type.TTypesOAuthKey): void;
  (e: 'shuffle'): void;
}
