import type { TPossibleNull } from '~/types/Shared.types';

export const useMapStore = defineStore('techies/map', () => {
  const highlightedColumn = ref<TPossibleNull<number>>(null);

  const handleHover = (column: number) => {
    highlightedColumn.value = column;
  };

  const handleLeave = () => {
    highlightedColumn.value = null;
  };

  return {
    handleHover,
    handleLeave,
    highlightedColumn,
  };
});
