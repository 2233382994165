<template>
  <div class="player-icon">
    <SvgoTechiesPlayerArrow class="player-icon__arrow" />
    <NuxtImg class="player-icon__image" :src="playerIcon" provider="localProvider" @mouseup="changeIcon" />
  </div>
</template>

<script setup lang="ts">
import type { TPossibleNull } from '~/types/Shared.types';

const icons = ['fbi', 'glass', 'ada', 'swat', 'chicken'];
let arrowDiv: TPossibleNull<HTMLElement> = null;

const { isMobileOrTablet } = useDevice();

const currentIcon = ref(0);
const arrowAngle = ref(0);

const playerIcon = computed(() => getS3Image('techies', `player-icons/${icons[currentIcon.value]}`));

const arrowInRad = computed(() => `${arrowAngle.value}rad`);

const changeIcon = () => {
  currentIcon.value = (currentIcon.value + 1) % icons.length;
};

const handleMouseMove = (event: MouseEvent) => {
  if (!arrowDiv) return;

  const target = event.target as HTMLElement;
  if (target.classList.contains('player-icon__image')) return;

  const { clientX: mouseX, clientY: mouseY } = event;
  const { x: arrowX, y: arrowY, width: arrowWidth, height: arrowHeight } = arrowDiv.getBoundingClientRect();

  // Расчитывается центр блока стрелки
  const arrowCenterX = arrowX + arrowWidth / 2;
  const arrowCenterY = arrowY + arrowHeight / 2;

  // Расчитывается угол отклонения мыши от центра стрелки
  arrowAngle.value = Math.atan2(mouseY - arrowCenterY, mouseX - arrowCenterX);
};

onMounted(() => {
  if (!document) return;
  arrowDiv = document.querySelector('.player-icon__arrow');

  if (isMobileOrTablet) return;
  document.addEventListener('mousemove', handleMouseMove, { passive: true });
});

onUnmounted(() => {
  if (!document || isMobileOrTablet) return;
  document.removeEventListener('mousemove', handleMouseMove);
});
</script>

<style scoped lang="scss">
.player-icon {
  --arrow-rotate: v-bind('arrowInRad');
}
</style>

<style scoped lang="scss" src="./PlayerIcon.scss" />
