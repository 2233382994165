<template>
  <section class="stages-bar-controller">
    <TechiesFieldSign
      v-for="stage in STAGES"
      :key="stage"
      class="stages-bar-controller__item"
      :state="stageState(stage)"
    />
  </section>
</template>

<script setup lang="ts">
import { EFieldSignStates } from '~/features/techies/components/FieldSign/FieldSign.types';
import { STAGES } from '~/features/techies/constants/rules.constants';

import { useInfoStore } from '~/project-layers/cs/features/techies/store/info.store';
import { usePlayerStore } from '~/features/techies/store/player.store';

const infoStore = useInfoStore();
const { isGameStarted } = storeToRefs(infoStore);

const playerStore = usePlayerStore();
const { coords } = storeToRefs(playerStore);

const stageState = computed(() => {
  const currentStage = coords.value.x + 2;

  return (stage: number) => {
    if (!isGameStarted.value) return EFieldSignStates.DEFAULT;

    if (stage < currentStage) return EFieldSignStates.DONE;
    if (stage === currentStage) return EFieldSignStates.ACTIVE;
    return EFieldSignStates.DEFAULT;
  };
});
</script>

<style scoped lang="scss" src="/features/techies/controllers/StagesBarController/StagesBarController.scss" />
